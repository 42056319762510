import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { FaEnvelope, FaPhoneAlt, FaChevronDown, FaBars } from 'react-icons/fa'
// import uniCollegeLogoWhiteSVG from './svg/unicollegeLogoWhiteSVG'
// import logo from '../images/logos/unicollege-logo.jpeg'
import logoSVG from '../../images/logos/unicollege-logo-white-cropped.svg'

const Navbar = ({learningMethods, studyFields, location}) => {
  const [showMenu, setShowMenu] = useState(false)
  const navRef = useRef({ location: null })

  // Closes mobile nav menu on route change
  // Got help from: https://stackoverflow.com/questions/61274365/allow-component-to-detect-route-change-in-gatsby
  useEffect(() => {
    if (!navRef.current.location) {
      navRef.current.location = location
    } else if (navRef.current.location !== location) {
      if (showMenu) {
        setShowMenu(false)
      }
      navRef.current.location = location
    }
  }, [location, showMenu])

  const toggleMobileMenu = () => {
    setShowMenu(prevShowMenu => !prevShowMenu)
  }

  const menuClasses = showMenu ? 'show' : ''

  return (
    <>
      <StyledContactLinksContainer>
        <a href="mailto:info@unicollege.co.za"><FaEnvelope />&nbsp; <span>info@unicollege.co.za</span></a>
        <a href="tel:0119555326"><FaPhoneAlt />&nbsp; <span>011 955 5326</span></a>
        {/* <Link id='appointment-btn' to='/book-an-appointment' className='btn-nav'>Book an appointment</Link> */}
        <Link to='/contact/' className='btn-nav'>Enquire</Link>
        <Link to='/registration/' className='btn-nav'>Register</Link>
        <a href='https://unicollege.campusonline.co.za/portal' className='btn-nav' id='student-login-btn'>Student Login</a>
      </StyledContactLinksContainer>

      <StyledNavbar>
        <div className="navigation">
          <Link to='/' id='logo'>
            <img src={logoSVG} alt="UniCollege Logo" />
            {/* <uniCollegeLogoWhiteSVG /> */}
          </Link>
          
          <ul className={menuClasses} ref={navRef}>
            <li><span>About Us <FaChevronDown /></span>
              <ul className="dropdown-list">
                <li><Link to='/about/'>About Us</Link></li>
                <li><Link to='/accreditations/'>Our Accreditations</Link></li>
                {/* <li><Link to='/our-team/'>Our Team</Link></li> */}
              </ul>
            </li>
            <li><span><Link to='/learning-methods/'>Learning Methods  <FaChevronDown /></Link></span>
              <ul className="dropdown-list">
                {learningMethods.map(({title, slug}) => (
                  <li key={slug.current}><Link to={`/learning-methods/${slug.current}/`}>{title}</Link></li>
                ))}
                <li><Link to='/upcoming-courses/'>Upcoming Courses</Link></li>
              </ul>
            </li>
            <li><span><Link to='/study-fields/'>Study Fields <FaChevronDown /></Link></span>
              <ul className="dropdown-list">
                {studyFields.map(({title, slug}) => (
                  <li key={slug.current}><Link to={`/study-fields/${slug.current}/`}>{title}</Link></li>
                ))}
              </ul>
            </li>
            <li><span>Enrolment <FaChevronDown /></span>
              <ul className="dropdown-list">
                <li><Link to='/book-an-appointment/'>Schedule an Appointment</Link></li>
                <li><Link to='/book-an-appointment/'>Book a Career Assessment</Link></li>
                <li><Link to='/enrolment/fees-payment-options/'>Fees and Payment Options</Link></li>
                <li><Link to='/registration/'>Register</Link></li>
              </ul>
            </li>
            <li><Link to='/our-campus/'>Our Campus</Link></li>
            <li><span><Link to='/news/'>News & Reviews <FaChevronDown /></Link></span>
              <ul className="dropdown-list">
                <li><Link to='/news?category=Campus%20News'>Campus News</Link></li>
                <li><Link to='/news?category=Career%20%26%20Study%20Advice'>Career & Study Advice</Link></li>
                <li><Link to='/news?category=Student%20Success%20Stories'>Student Success Stories</Link></li>
              </ul>
            </li>
            <li><Link to='/contact/'>Contact Us</Link></li>
            <li><a href='https://unicollege.campusonline.co.za/portal' id='student-login-btn'>Student Login</a></li>
          </ul>

          <FaBars id="burger-icon" onClick={toggleMobileMenu} />
        </div>
        
        {/* <div class="scaling-svg-container" style={{ paddingBottom: '2.91667%' }}> */}
        <svg 
          id='border-bottom' 
          width="1440" 
          height="42" 
          viewBox="0 0 1440 42" 
          // preserveAspectRatio="xMidYMin slice" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
          // style={{
          //   width: '100%',
          //   paddingBottom: '2.91667%',
          //   height: 1,
          //   overflow: 'visible'
          // }}
        >
          <path d="M1440 0H0V22C0 22 108 66.5 646.5 22C1185 -22.5 1440 22 1440 22V0Z" fill="#B50E20"/>
        </svg>
        {/* </div> */}

        {/* <svg fill="none" height="230" viewBox="0 0 1440 230" width="100%" xmlns="http://www.w3.org/2000/svg">
          <path d="m0 0h1440v229h-1440z" fill="#b50e20"/>
          <g fill="#fff">
            <path d="m683.527 192.648c-436.829 52.94-530.408 15.437-738.527 0v36.352h1867v-36.352c-382.22-40.834-623.25-61.228-1128.473 0z" fillOpacity=".5"/>
            <path d="m580.5 192.805c-396 53.155-480.8333 15.5-669.5 0v36.5h1692.5v-36.5c-346.5-41-565-61.477-1023 0z" fillOpacity=".5"/>
            <path d="m669.5 192.845c-396 53.155-480.833 15.5-669.5 0v36.5h1692.5v-36.5c-346.5-41-565-61.477-1023 0z"/>
          </g>
        </svg> */}
      </StyledNavbar>
    </>
  )
}

const StyledContactLinksContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--primary-color);
  color: #fafafa;
  /* margin: 0 2rem; */
  padding: 0.5rem 2rem;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  font-size: 0.8rem;

  a {
    margin-left: 1rem;
    text-decoration: none;
  }

  a.btn-nav {
    display: inline-block;
    background: #fafafa;
    color: #111;
    padding: 0.6em 1em;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    padding: 0.5rem;

    a {
      span {
        clip: rect(0 0 0 0); 
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap; 
        width: 1px;
      }
    }

    #appointment-btn {
      display: none;
    }
  }

  @media (max-width: 480px) {
    svg {
      font-size: 1.1rem;
    }
    
    a#student-login-btn {
      display: none;
    }
  }
`

const StyledNavbar = styled.nav`
  background-color: var(--primary-color);
  color: #fafafa;
  position: relative;
  z-index: 101;

  a {
    text-decoration: none;
  }

  div.navigation {
    display: flex;
    justify-content: space-between;
    padding: 0.6rem 1rem 0 2rem;
    z-index: 1;

    #logo {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
    }

    ul {
      color: inherit;
      list-style: none;

      li {
        padding: 0.75em 1em;
      }
    }

    & > ul {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      align-items: center;

      & > li {
        flex: 0 0 auto;
        position: relative;
      }
    }

    & > ul > li:hover > ul.dropdown-list {
      display: block;
    }
    
    ul.dropdown-list {
      position: absolute;
      top: 100%;
      display: none;
      background-color: rgba(181, 14, 32, 0.7);
      backdrop-filter: blur(3px);
      min-width: 14rem;
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);
      -webkit-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);
      -moz-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);
      z-index: 1;

      li {
        padding: 0;
      }

      a {
        display: block;
        padding: 0.75em 1em;

        &:hover {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }
    }

    #burger-icon {
      display: none;
      font-size: 2rem;
      z-index: 1;
    }
  }

  div.scaling-svg-container {
    position: absolute;
    top: calc(100% - 1px);
    z-index: -1;
  }

  svg#border-bottom {
    width: 100%;
    /* height: 100%; */
    /* padding: 0;
    padding-bottom: calc(42 / 1440 * 100); */
    /* -webkit-height: min-content;
    -moz-height: min-content; */
    height: auto;
    position: absolute;
    top: calc(100% - 1px);
    filter: drop-shadow(0 6px 5px rgba(0, 0, 0, 0.1));
    z-index: -1;
  }

  @media (min-width: 769px) {
    #logo {
      padding-left: 2rem;
    }
  }

  @media (max-width: 768px) {
    position: sticky;
    top: 0;
    
    div.navigation {
      align-items: center;

      & > ul {
        flex-flow: column nowrap;
        justify-content: flex-start;
        background-color: var(--primary-color);
        width: 80vw;
        max-width: 500px;
        padding-top: 8rem;
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        transform: translateX(100%);
        transition: transform 0.25s;
        box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);
        -webkit-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);
        -moz-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);
        overflow-y: scroll;

        & > li {
          width: 100%;
        }

        &.show {
          transform: translateX(0);
        }
      }

      ul.dropdown-list {
        position: static;
        border-radius: 0;
        border-left: solid 2px #fafafa;
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
      }

      #burger-icon {
        display: initial;
      }
    }
  }

  @media (max-width: 480px) {
    div.navigation {
      padding: 0.6rem;

      #logo {
        img {
          width: 200px;
        }
      }
    }
  }

  @media (min-width: 481px) {
    a#student-login-btn {
      display: none;
    }
  }
`

export default Navbar
